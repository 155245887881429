import {
    AfterContentChecked,
    Component,
    Injector,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core"
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { CHNode } from "../../models/node.model"
import { BrowsingService } from "../../services/browsing.service"
import { LocalizationService } from "../../services/localization.service"
import { UserService } from "../../services/user.service"
import { ModalService } from "../modal/modal.service"
import { AuthModalComponent } from "../modals/auth-modal/auth-modal.component"
import { AssignmentsNotStartedService } from "../../services/assignments-not-started.service"
import { STATIC_IMAGES } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"
import { StudentGateService } from "../../services/student-gate.service"
import {
    IWindowResizeEvent,
    ScreenBreakpoints,
    WindowSizeService,
} from "../../services/window-size.service"
import Utils from "../../utils/utils"
import { StudentSignInModalComponent } from "../modals/student-sign-in-modal/student-sign-in-modal.component"
import { Localize } from "../../models/localize.model"
import { fadeInXY, rotateRight, routeAnimations, routeTransition } from "../../utils/animations"
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap"

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    animations: [fadeInXY, routeAnimations, rotateRight],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {
    logoImage: string = null
    logoImageWhite: string = null
    logoImageStateWhite: string = null
    logoImageState: string = null
    localizationLogo: string = null
    showBurgerMenu: boolean = false
    showUserMenu: boolean = false
    isHomePage: boolean = false
    stateName: string = null
    gtmPrefix: string = "gtm-header-dropdown-"
    activeNode: CHNode
    subscriptions: Subscription[] = []
    fullCommonName: string = "station"
    nextUrl: string = null
    assignmentsNotStarted: number = 0
    homeLink: string = ""
    isAddonView: boolean = false
    isTeacherPreview: boolean = false
    logOutUrl: string
    isTablet: boolean = false
    isGateOpened: boolean = false
    isDropdownBackdropVisible: boolean = false
    isStudentExperience: boolean = false
    selectedAddOn: string = null

    isOpenLoggedInUserMenu: boolean = false
    isOpenLocalizationMenu: boolean = false

    @ViewChild("dropdown", { static: false }) dropdown: NgbDropdown
    @ViewChild("localizationDropdown", { static: false }) localizationDropdown: NgbDropdown

    constructor(
        public userService: UserService,
        public addOnService: AddOnViewService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private browsingService: BrowsingService,
        private route: ActivatedRoute,
        private injector: Injector,
        private router: Router,
        public studentGateService: StudentGateService,
        private windowSizeService: WindowSizeService
    ) {
        this.logoImage = STATIC_IMAGES.pbslm_logo
        this.logoImageWhite = STATIC_IMAGES.pbslm_logo_white
        this.logoImageStateWhite = STATIC_IMAGES.pbslm_logo_open_area_inverse
        this.logoImageState = STATIC_IMAGES.pbslm_logo_open_area

        this.isHomePage = window.location.pathname === "/"
    }

    ngOnInit() {
        this.isTeacherPreview = this.addOnService.isTeacherPreview
        this.isAddonView = this.addOnService.isAddonView
        if (this.isAddonView) this.selectedAddOn = this.addOnService.selectedAddOn
        this.checkStudentExperienceState()
        this.logOutUrl = this.userService.getLogoutUrl()
        this.subscriptions.push(
            this.studentGateService.isGateOpened.subscribe((value: boolean) => {
                this.isGateOpened = value
                this.isDropdownBackdropVisible = value
            })
        )

        this.subscriptions.push(
            this.localizationService.localizeData.subscribe((data: Localize) => {
                this.localizationLogo = data.getLogo()
                this.fullCommonName = data.fullCommonName
                this.stateName =
                    this.userService.hasStudentExperience() && !this.isHomePage
                        ? "for students"
                        : data.stateName
            })
        )

        this.subscriptions.push(
            this.browsingService.activeNodeChanged.subscribe((node: CHNode) => {
                this.activeNode = node
            })
        )

        this.subscriptions.push(
            this.route.queryParams.subscribe((params: Params) => {
                if (window.location.pathname.includes("signup")) {
                    this.nextUrl = params["next"]
                }
            })
        )

        this.subscriptions.push(
            this.router.events.subscribe((e: any) => {
                if (e instanceof NavigationEnd) {
                    this.closeRightHandMenus()
                }
            })
        )

        this.subscriptions.push(
            this.windowSizeService.resize.subscribe((value: IWindowResizeEvent) => {
                this.isTablet = value.isTablet
                this.studentGateService.isLgModalDisplayed = false
                if (!this.studentGateService.isModalBackdropHidden)
                    this.handleStudentGateModalOnResize(value)
            })
        )
        this.getAssignmentsNotStartedForStudent()
    }

    handleStudentGateModalOnResize(event: IWindowResizeEvent) {
        if (event.isTablet && this.studentGateService.isDropdownActive) {
            this.studentGateService.closeStudentGateModal()
            this.studentGateService.isGateActive = true
        } else if (
            !this.studentGateService.isDropdownActive &&
            event.innerWidth >= ScreenBreakpoints.Tablet
        ) {
            this.studentGateService.isGateActive = false
            this.studentGateService.openStudentGateModal()
        }
    }

    ngOnChanges() {
        this.checkStudentExperienceState()
    }

    ngAfterContentChecked() {
        this.homeLink = this.getHomeLink()
        this.checkStudentExperienceState()
    }

    navigateToProfile() {
        this.router.navigate([
            this.isAddonView ? `${this.selectedAddOn}/add-on/profile/` : "profile",
        ])
    }

    getHomeLink(): string | null {
        if (this.userService.hasStudentExperience()) {
            return this.isAddonView ? null : "/student/"
        } else {
            return this.isAddonView ? `/${this.addOnService.selectedAddOn}/add-on/search/` : "/"
        }
    }

    // TODO: simplify this function
    hasAccessToRightMenu(): boolean {
        return (
            (this.userService.isStudent() && !this.addOnService.isAddonView) ||
            !this.userService.isStudent()
        )
    }

    toggleMenu(type: string = "burger-menu") {
        if (type === "burger-menu") {
            this.showBurgerMenu = !this.showBurgerMenu
            this.showUserMenu = false
        } else {
            if (this.userService.isLoggedIn()) {
                this.showUserMenu = !this.showUserMenu
                this.showBurgerMenu = false
            } else {
                this.openAuthModal()
            }
        }
    }

    openAuthModal(): void {
        const data = this.nextUrl
            ? {
                  nextURL: this.nextUrl,
              }
            : {}

        this.modalService.open(
            this.userService.hasStudentExperience()
                ? StudentSignInModalComponent
                : AuthModalComponent,
            {
                data,
            }
        )
    }

    getName(): string {
        if (this.userService.getFirstName()) {
            return this.userService.getFirstName()
        } else {
            // INFO: Agreement: Display "Account" noun when the user has no first name set.
            return "Account"
        }
    }

    checkStudentExperienceState() {
        this.isStudentExperience =
            Utils.hasStudentExperience() || this.userService.hasStudentExperience()
    }

    closeRightHandMenus(): void {
        this.showUserMenu = false
        this.showBurgerMenu = false
    }

    /**
     * Inject service for getting the number of not started assignments
     * only if the user has student experience
     * @private
     */
    private getAssignmentsNotStartedForStudent() {
        if (!this.userService.hasStudentExperience() || !this.userService.isLoggedIn()) return
        const service: AssignmentsNotStartedService = this.injector.get(
            AssignmentsNotStartedService
        )
        this.subscriptions.push(
            service.assignmentsNotStarted.subscribe(
                (notStartedCount: number) => (this.assignmentsNotStarted = notStartedCount)
            )
        )
    }

    toggleGate(isMobile: boolean) {
        this.studentGateService.isGateActive = !this.isGateOpened
        this.studentGateService.isLgModalDisplayed = false
    }

    ngOnDestroy() {
        this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
    }

    toggleLoggedInUserMenu() {
        this.isOpenLoggedInUserMenu = !this.isOpenLoggedInUserMenu
    }

    toggleLocalizationMenu() {
        this.isOpenLocalizationMenu = !this.isOpenLocalizationMenu
    }
}
