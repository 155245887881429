import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import Utils from "../../../utils/utils"
import { STATIC_IMAGES } from "../../../models/constants"

@Component({
    selector: "app-login-schoology",
    templateUrl: "./schoology-add-on.component.html",
    styleUrls: ["./schoology-add-on.component.scss"],
})
export class SchoologyAddOnComponent implements OnInit {
    areCookiesEnabled: boolean = false
    isSafariBrowser: boolean = false
    isStudentAccountSelected: boolean = false
    PBSLMLogo: string = STATIC_IMAGES.pbslm_logo
    schoologyImg: string = STATIC_IMAGES.schoology_icon
    loginMessage: string =
        "Please sign in with your Schoology account in order to access PBS LearningMedia’s free teaching resources."

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.isSafariBrowser = Utils.isSafari()
        this.areCookiesEnabled = Utils.areCookiesEnabled()

        const queryParam: string = this.activatedRoute.snapshot.queryParamMap.get("logOut")
        if (!queryParam) return
        this.isStudentAccountSelected = true
        this.loginMessage =
            "Your account does not have permission to access this service. Sign out and try signing in with a Schoology teacher account."
    }

    logOut() {
        window.location.href = decodeURIComponent("/logout/?next=/schoology/add-on/search/")
    }

    openLoginPopUp(): void {
        // after BE implementation, new changes will be done
        const loginUrl: string =
            "/login/schoology/?return_url=https%3A%2F%2Fqa.pbslm.org%2Fcomplete%2Fschoology%2F"
        // "/login/schoology/?return_url=https%3A%2F%2Fqa.pbslm.org%2Fcomplete%2Fschoology%2F"
        // "/schoology/add-on/log-in/?social_strategy=schoology&backend=schoology&return_url=https%3A%2F%2Fqa.pbslm.org%2Fcomplete%2Fschoology%2F"
        // const loginUrl: string = "/login/schoology/"
        //`https://app.schoology.com/oauth/authorize/?is_ssl=1&realm_id=53150894&realm=user&app_id=1142309186&oauth_token=330ce618a9e6c7d89c97a92879e0d02c0677e93a7&return_url=${window.location.origin}/schoology/add-on/close-login-popup/`
        // https://app.schoology.com/oauth/authorize?hd=&login_hint=&oauth_token=2b0846d874a86ec27b68db57ffbe9f5e065eed31a&return_url=https%3A%2F%2Fwww.pbslearningmedia.org%2Fcomplete%2Fschoology%2F
        Utils.openPopUp(loginUrl)
    }
}
